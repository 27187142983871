function trackTrialActivation() {
  dataLayer.push({
    event: "downloadEvent",
    category: "Articulate 360 Inline Trial",
    action: "360 Free Trial",
    label: "Sub",
    nonInteraction: true,
  });
}

export default trackTrialActivation;
