const paramsToFilter = ["product"];

const searchParams = () => {
  const paramsWithSearch = {};
  const urlSearchParams = new URLSearchParams(window.location.search);
  const filteredParams = filterSearchParams(urlSearchParams);
  const entries = urlSearchParams.entries();
  let currentEntry = entries.next();

  while (!currentEntry.done) {
    const entryValue = currentEntry.value;
    const key = entryValue[0];
    const value = entryValue[1];

    paramsWithSearch[key] = value;
    currentEntry = entries.next();
  }

  return paramsWithSearch;
};

const getUrlObject = (redirect) => {
  let url;

  try {
    url = new URL(redirect);

    return url;
  } catch (error) {
    url = new URL(window.location.origin + redirect);

    return url;
  }
};

const urlWithSearchParams = (urlString) => {
  const url = getUrlObject(urlString);
  const object = searchParams();

  Object.entries(object).forEach((entry) => {
    const [key, value] = entry;
    url.searchParams.set(key, value);
  });

  return url;
};

const filterSearchParams = (searchParams) => {
  for (let i = 0; i < paramsToFilter.length; i++) {
    let param = paramsToFilter[i];
    searchParams.delete(param);
  }

  return searchParams;
};

const getRedirectURLWithSearchParams = (redirectURL) => {
  if (!redirectURL) {
    return null;
  }

  return urlWithSearchParams(redirectURL).toString();
};

module.exports = {
  getRedirectURLWithSearchParams,
};
