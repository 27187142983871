import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import * as forms from "../../actions/forms";

const mapStateToProps = ({ forms }, props) => {
  const stateProps =
    {
      value: forms[props.id] || props.defaultValue || false,
      error: forms[`${props.id}Error`],
    } || props.error;
  return _.merge({}, stateProps, props);
};

const mapDispatchToProps = (dispatch, { id }) => {
  return {
    updateInputValue: (e) => {
      return dispatch(
        forms.updateInputValue({ inputId: id, value: e.target.checked })
      );
    },
  };
};

class Checkbox extends Component {
  render() {
    const { label, id, value, updateInputValue, error } = this.props;
    return (
      <div
        className={classNames(
          "flex items-baseline checkbox__terms padding--bottomXSmall size--xxxsmall color--secondary",
          { errors: error }
        )}
      >
        <input
          className="checkbox__termsCheckbox"
          data-label="terms-checkbox"
          id={id}
          type="checkbox"
          checked={value}
          onChange={updateInputValue}
        />
        <span className="max-w-sm" dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
