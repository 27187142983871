import React from "react";

interface FormOverlay {
  children: string | JSX.Element;
}

function FormOverlay({ children }: FormOverlay) {
  return (
    <div className="absolute | flex | flex-col | justify-center | left-0 | top-0 | bg-white | bg-opacity-90 | h-full | w-full | rounded-none | z-10 | sm:rounded-form">
      <p className="font-semibold | text-xl | px-4 | mx-auto | !leading-normal | max-w-sm | sm:!text-2xl | sm:!leading-relaxed | sm:px-0">
        {children}
      </p>
    </div>
  );
}

export default FormOverlay;
