import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as forms from "../../actions/forms";
import * as session from "../../actions/session";
import * as ui from "../../actions/ui";
import * as trials from "../../actions/trials";
import TourCtaForm from "./tourpage/TourCtaForm";
import { updateInputValue } from "../../actions/forms";

const formFields = {
  email: _.uniqueId("email"),
  password: _.uniqueId("password"),
  first_name: _.uniqueId("first_name"),
  last_name: _.uniqueId("last_name"),
  company: _.uniqueId("company"),
  country: _.uniqueId("country"),
  phone: _.uniqueId("phone"),
  marketingConsent: _.uniqueId("marketingConsent"),
  termsConsent: _.uniqueId("termsConsent"),
};

const initialSubmitBtn = React.createRef();
const expandedSubmitBtn = React.createRef();

const mapStateToProps = ({ trials, session, forms, ui }, ownProps) =>
  _.merge(
    {
      initialSubmitBtn,
      expandedSubmitBtn,
      isRequestingTrialActivation: trials.isRequestingTrialActivation,
      isLookingUpEmail: session.isLookingUpEmail,
      isExistingUser: session.isExistingUser,
      signOutName: session.firstName,
      shouldShowInitial: ui.tourCtaTrialInitialFormIsOpen,
      shouldShowExpanded: ui.tourCtaTrialExpandedFormIsOpen,
      didExpand: ui.tourCtaTrialExpandedFormDidOpen,
      ids: formFields,
      email: forms[formFields.email],
      firstName: forms[formFields.first_name],
      defaultEmail: ownProps.email,
      didActivateTrial: trials.didActivateTrial,
      activationError: trials.trialActivationError || session.emailLookupError,
      requiresMarketingOptIn: ownProps.requiresMarketingOptIn,
    },
    ownProps
  );

const mapDispatchToProps = (dispatch, ownProps) => {
  // setting redux value of email input to match logged in user, so validation works for logged in user
  if (ownProps.email)
    dispatch(
      updateInputValue({ inputId: formFields.email, value: ownProps.email })
    );
  return {
    requestLookupUserByEmail: (payload) =>
      dispatch(session.requestLookupUserByEmail(payload)),
    validateFormData: () => dispatch(forms.validateFormData(data)),
    handleInitialFormSubmit: () =>
      dispatch(
        trials.submitInitialInlineForm(
          _.merge(
            {},
            {
              inputId: formFields.email,
              submitBtn: initialSubmitBtn,
              expandForm: () => {
                dispatch(ui.toggleTourCtaTrialForm("Initial", false));
              },
            }
          )
        )
      ),
    handleExpandedFormSubmit: (data) =>
      dispatch(trials.submitExpandedInlineForm(data)),
    showExpanded: () => dispatch(ui.toggleTourCtaTrialForm("Expanded", true)),
    handleDidExpand: () => dispatch(ui.toggleTourCtaBtns()),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  _.merge({}, stateProps, dispatchProps, ownProps, {
    handleSubmit: (isExistingUser, e) => {
      e.preventDefault();
      let ids;
      if (isExistingUser)
        ids = {
          email: formFields.email,
          password: formFields.password,
          termsConsent: formFields.termsConsent,
          marketingConsent: formFields.marketingConsent,
        };
      else ids = _.merge({}, formFields);

      const data = _.merge(
        {},
        {
          formFields: ids,
          token: ownProps.csfrToken,
          isLoggedIn: ownProps.isLoggedIn,
          requiresMarketingOptIn: ownProps.requiresMarketingOptIn,
        }
      );

      if (stateProps.shouldShowInitial)
        return dispatchProps.handleInitialFormSubmit(data);
      return dispatchProps.handleExpandedFormSubmit(data);
    },
  });

function TourCtaTrial(props) {
  const { showInitial, showExpanded, shouldShowInitial, shouldShowExpanded } =
    props;
  return (
    <div>
      <div className="align--center">
        <h2
          data-label="cta-trial-header"
          dangerouslySetInnerHTML={{
            __html: props.reach ? i18next.t("inline_trial:reach.header_html") : i18next.t("inline_trial:tour.header_html"),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: props.reach ? i18next.t("inline_trial:reach.subheader_html") : i18next.t("inline_trial:tour.subheader_html"),
          }}
        />
      </div>
      <TourCtaForm {...props} />
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TourCtaTrial);
