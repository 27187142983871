import Icon from "../Icon";

const SectionModal = ({
  className,
  children,
  toggleSectionModal,
  ariaLabelledby,
}) => {
  return (
    <div
      role="dialog"
      ariaLabelledby={ariaLabelledby}
      className="sectionModal__container"
      onClick={toggleSectionModal}
    >
      <div
        className={`sectionModal ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <Icon
          name="times-skinny"
          size="xsmall"
          color="quarternary"
          styleclass="modal__closeBtn"
          onClick={toggleSectionModal}
        />
        {children}
      </div>
    </div>
  );
};

export default SectionModal;
