import React, { useEffect, useState } from "react";
import DOMPurify from "../../lib/domPurify";

import Modal from "./Modal";

interface Props {
  ctaPath: string;
  header: string;
  body: string;
  ctaCopy: string;
  triggerDelay?: number;
}

function ExitIntentModal({
  ctaPath,
  header,
  body,
  ctaCopy,
  triggerDelay,
}: Props): JSX.Element {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("mouseout", handleMouseOut);
    }, triggerDelay);
  });

  const handleMouseOut = (event: MouseEvent): void => {
    if (isExitIntent(event) && shouldShowModal()) {
      triggerModal();
    }
  };

  const shouldShowModal = (): boolean => {
    return (
      !sessionStorage.getItem("exitIntentShown") &&
      document.getElementsByClassName("modal__wrapper active").length === 0
    );
  };

  const isExitIntent = (event: MouseEvent): boolean => {
    const newElementEntered: EventTarget = event.relatedTarget;
    const mouseHeadingForExit: boolean = event.clientY < 10;
    return !newElementEntered && mouseHeadingForExit;
  };

  const triggerModal = (): void => {
    document.removeEventListener("mouseout", handleMouseOut);
    sessionStorage.setItem("exitIntentShown", "true");
    setToggleModal(true);
  };

  const closeModal = (): void => {
    setToggleModal(false);
  };

  if (!ctaPath) {
    return null;
  }

  return (
    <Modal
      isActive={toggleModal}
      handleClose={closeModal}
      modalClass="modal--wide"
      modalName="mtc"
    >
      <div>
        <h1
          id="modalHeader"
          className="h2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(header),
          }}
        ></h1>
        <p
          id="modalBody"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(body),
          }}
        ></p>
        <a
          className="btn--primary"
          href={ctaPath}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(ctaCopy),
          }}
        />
      </div>
    </Modal>
  );
}

ExitIntentModal.defaultProps = {
  triggerDelay: 30000,
};

export default ExitIntentModal;
