const SignInLink = ({ loginUrl }) => {
  return (
    <div className="size--xxsmall">
      {i18next.t("auth:form.already_a_user")}
      <a href={loginUrl}>{i18next.t("auth:form.sign_in_html")}</a>
    </div>
  );
};

export default SignInLink;
