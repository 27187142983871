import "./accents/accent-box-engage.webp";
import "./accents/accent-box-presenter.webp";
import "./accents/accent-box-quizmaker.webp";
import "./accents/accent-box-replay.webp";
import "./accents/accent-chart-skateboarder@1x.webp";
import "./accents/accent-chart-skateboarder@2x.webp";
import "./accents/accent-comments@1x.webp";
import "./accents/accent-comments@2x.webp";
import "./accents/accent-content-labels.webp";
import "./accents/accent-interaction-properties.webp";
import "./accents/accent-kiwis@1x.webp";
import "./accents/accent-kiwis@2x.webp";
import "./accents/accent-peek-preview@1x.webp";
import "./accents/accent-peek-preview@2x.webp";
import "./accents/accent-people-training@1x.webp";
import "./accents/accent-people-training@2x.webp";
import "./accents/accent-product-suite@1x.webp";
import "./accents/accent-product-suite@2x.webp";
import "./accents/accent-productdiscs-share.webp";
import "./accents/accent-review-discussion@1x.webp";
import "./accents/accent-review-discussion@2x.webp";
import "./accents/accent-review-resolve@1x.webp";
import "./accents/accent-review-resolve@2x.webp";
import "./accents/accent-seat-management@1x.webp";
import "./accents/accent-seat-management@2x.webp";
import "./accents/accent-sl3-cc.webp";
import "./accents/accent-sl3-dials.webp";
import "./accents/accent-sl3-efficiency.webp";
import "./accents/accent-sl3-html5.webp";
import "./accents/accent-sl3-interactions.webp";
import "./accents/accent-sl3-responsive.webp";
import "./accents/accent-sl3-tables.webp";
import "./accents/accent-sound-wave@1x.webp";
import "./accents/accent-sound-wave@2x.webp";
import "./accents/accent-strawberries@1x.webp";
import "./accents/accent-strawberries@2x.webp";
import "./accents/accent-team-administration@1x.webp";
import "./accents/accent-team-administration@2x.webp";
import "./accents/accent-timeline.webp";
import "./accents/accent-video-heart-rs@1x.webp";
import "./accents/accent-video-heart-rs@2x.webp";
import "./accents/ao-download@1x.webp";
import "./accents/ao-download@2x.webp";
import "./accents/signature@1x.webp";
import "./accents/signature@2x.webp";
import "./backgrounds/360-pricing-calculator-bg.webp";
import "./backgrounds/pdf-sun.webp";
import "./backgrounds/pdf-balloon.webp";
import "./backgrounds/pdf-clouds.webp";
import "./backgrounds/reach-stars.svg";
import "./boxes/replay_1.webp";
import "./boxes/storyline_1.webp";
import "./boxes/storyline_2.webp";
import "./boxes/storyline_3.webp";
import "./boxes/studio_09.webp";
import "./boxes/studio_13.webp";
import "./ebooks/effective-strategies.webp";
import "./ebooks/elearning-for-beginners.webp";
import "./ebooks/multi-device-elearning.webp";
import "./icons/icon-chevron-left.svg";
import "./icons/icon-chevron-right.svg";
import "./icons/icon-gift.svg";
import "./icons/lightning-gold.svg";
import "./icons/lightning.svg";
import "./illustrations/icons/icon-award-ribbon2.webp";
import "./illustrations/icons/icon-chat-bubbles.webp";
import "./illustrations/icons/icon-gradcap.webp";
import "./illustrations/icons/icon-source.webp";
import "./illustrations/illustration-360-astronauts.webp";
import "./illustrations/illustration-a360.webp";
import "./illustrations/illustration-billing.webp";
import "./illustrations/illustration-construction-worker.webp";
import "./illustrations/illustration-files.webp";
import "./illustrations/illustration-gated-success.webp";
import "./illustrations/illustration-high5-clouds.webp";
import "./illustrations/illustration-highwire.webp";
import "./illustrations/illustration-powerpoint@1x.webp";
import "./illustrations/illustration-powerpoint@2x.webp";
import "./illustrations/illustration-rise-blocks@1x.webp";
import "./illustrations/illustration-rise-blocks@2x.webp";
import "./illustrations/illustration-rocketman.webp";
import "./illustrations/illustration-soccer-circle@1x.webp";
import "./illustrations/illustration-soccer-circle@2x.webp";
import "./illustrations/illustration-soccer@1x.webp";
import "./illustrations/illustration-soccer@2x.webp";
import "./illustrations/illustration-storyline3@1x.webp";
import "./illustrations/illustration-storyline3@2x.webp";
import "./illustrations/illustration-rise-birds-2.webp";
import "./illustrations/illustration-rise-circle-left.webp";
import "./illustrations/illustration-rise-cloud-2.webp";
import "./illustrations/illustration-rise-cloud-3.webp";
import "./illustrations/reach-icon-contractors.svg";
import "./illustrations/reach-icon-data.svg";
import "./illustrations/reach-icon-globe.svg";
import "./illustrations/reach-icon-graph.svg";
import "./illustrations/reach-icon-handshake.svg";
import "./illustrations/reach-icon-heart-out.svg";
import "./illustrations/reach-icon-heart.svg";
import "./illustrations/reach-icon-manage.svg";
import "./illustrations/reach-icon-money.svg";
import "./illustrations/reach-icon-person-out.svg";
import "./illustrations/reach-icon-stopwatch.svg";
import "./illustrations/reach-person.svg";
import "./logos/logo-supergoop-rich.webp";
import "./logos/logo-supergoop.webp";
import "./logos/logo-supergoop-small.webp";
import "./logos/logo-soc2.webp";
import "./people/man-woman-IT@1x.webp";
import "./people/man-woman-IT@2x.webp";
import "./people/man-woman-employees@1x.webp";
import "./people/man-woman-employees@2x.webp";
import "./people/man-woman-trainers.webp";
import "./people/people-with-tablet@2x.webp";
import "./people/support-team@1x.webp";
import "./people/support-team@2x.webp";
import "./people/woman-with-card@2x.webp";
import "./product-badges/product-badge-ai.webp";
import "./product-badges/product-badge-reach.webp";
import "./product-badges/product-badge-review.webp";
import "./product-badges/product-badge-rise.webp";
import "./product-badges/product-badge-storyline.webp";
