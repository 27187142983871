import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import * as uiActions from "../../actions/ui";
import * as forms from "../../actions/forms";
import * as session from "../../actions/session";
import * as trials from "../../actions/trials";
import * as ui from "../../actions/ui";
import InitialForm from "./homepage/InitialForm";
import ExpandedForm from "./homepage/ExpandedForm";
import Fade from "../transitions/Fade";

const formFields = {
  email: _.uniqueId("email"),
  password: _.uniqueId("password"),
  first_name: _.uniqueId("first_name"),
  last_name: _.uniqueId("last_name"),
  company: _.uniqueId("company"),
  country: _.uniqueId("country"),
  phone: _.uniqueId("phone"),
  marketingConsent: _.uniqueId("marketingConsent"),
  termsConsent: _.uniqueId("termsConsent"),
};

const mapStateToProps = ({ ui, forms, session, trials }, ownProps) => {
  return _.merge(
    {},
    {
      isRequestingTrialActivation: trials.isRequestingTrialActivation,
      isLookingUpEmail: session.isLookingUpEmail,
      isExistingUser: session.isExistingUser,
      signOutName: session.firstName,
      shouldShowInitial: ui.homepageTrialInitialFormIsOpen,
      shouldShowExpanded: ui.homepageTrialExpandedFormIsOpen,
      ids: formFields,
      defaultEmail: ownProps.email,
      didActivateTrial: trials.didActivateTrial,
      activationError: trials.trialActivationError,
      email: forms[formFields.email],
      firstName: forms[formFields.first_name],
    },
    ownProps
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showExpanded: () => {
      return dispatch(ui.toggleHomepageTrialForm("Expanded", true));
    },
    showInitial: () => {
      return dispatch(ui.toggleHomepageTrialForm("Initial", true));
    },
    hideExpanded: () => {
      return dispatch(ui.toggleHomepageTrialForm("Expanded", false));
    },
    hideInitial: () => {
      return dispatch(ui.toggleHomepageTrialForm("Initial", false));
    },
    requestLookupUserByEmail: (payload) => {
      return dispatch(session.requestLookupUserByEmail(payload));
    },
    validateFormData: () => {
      return dispatch(forms.validateFormData(data));
    },
    handleExendedFormSubmit: (data) => {
      return dispatch(trials.submitExpandedInlineForm(data));
    },
  };
};

class HomepageTrial extends Component {
  constructor(props) {
    super(props);
    this.initial = React.createRef();
    this.expanded = React.createRef();
    this.getBackgroundHeight = this.getBackgroundHeight.bind(this);
  }

  componentDidMount() {
    // this element is added to prevent a page jump of 800px while the js is loading. it needs to be removed after so that the blurred form doesn't show the image behind and look like it isn't fully blurred.
    const bannerPlaceholder = document.querySelector(
      ".section--fullBg.img--background.background--homeBanner.home__hero"
    );
    bannerPlaceholder.remove();
  }

  getBackgroundHeight() {
    let childHeight = "";
    if (this.props.shouldShowInitial && this.initial.current)
      childHeight = window
        .getComputedStyle(this.initial.current)
        .getPropertyValue("height");
    else if (this.props.shouldShowExpanded && this.expanded.current)
      childHeight = window
        .getComputedStyle(this.expanded.current)
        .getPropertyValue("height");
    childHeight = Number(childHeight.replace("px", "")) + 80;
    childHeight = childHeight > 508 ? childHeight : 508; // the arbitrary minimum height of the initial homepage form on mobile
    return childHeight;
  }

  render() {
    const {
      showInitial,
      showExpanded,
      shouldShowInitial,
      shouldShowExpanded,
      signOutName,
    } = this.props;
    let childHeight = this.getBackgroundHeight();

    return (
      <div>
        <div
          className={classNames(
            "section--fullBg img--background background--homeBanner home__hero",
            { blurry: shouldShowExpanded }
          )}
          id="homepage-hero"
          style={{ minHeight: `${childHeight}px` }}
        >
          <Fade
            inProp={shouldShowInitial}
            onExited={showExpanded}
            unmountOnExit={true}
          >
            <InitialForm reference={this.initial} {...this.props} />
          </Fade>
          <Fade inProp={shouldShowExpanded} onExited={showInitial}>
            <ExpandedForm reference={this.expanded} {...this.props} />
          </Fade>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageTrial);
