import React, { useState } from "react";

interface Select {
  field: string;
  label: string;
  defaultOption: string;
  options: Array<string>;
}

function Select({ field, label, defaultOption, options }: Select) {
  const [error, setError] = useState("");

  const handleInvalid = (event: React.InvalidEvent<HTMLSelectElement>) => {
    setError(event.target.validationMessage);
  };

  const handleChange = () => {
    setError("");
  };

  return (
    <label className="relative w-full col-span-2 text-gray-aa mb-12 z-[1]">
      <select
        name={`${field}`}
        className={`bg-transparent | text-m | peer | font-graphik | sm:text-xl
                  ${error ? "| text-red-error" : ""}`}
        required
        onInvalid={handleInvalid}
        onChange={handleChange}
      >
        <option key="select" value="" className="font-medium">
          {defaultOption}
        </option>
        {optionsList(options)}
      </select>
      <span
        className={`absolute | text-s | leading-8 | bottom-8
                  ${error ? "| text-red-error" : ""}`}
      >
        {label}
      </span>
      <span className="icon-chevron-down | absolute | right-0 | bottom-2 | z-[-1]"></span>
      {error ? (
        <span className="absolute | -bottom-5 | text-xs | font-normal | text-red-error">
          {error}
        </span>
      ) : null}
    </label>
  );
}

function optionsList(options: Array<string>) {
  return options.map((option) => {
    return (
      <option key={option} value={option}>
        {option}
      </option>
    );
  });
}

export default Select;
