const SignOutLink = ({ first_name, email, redirect_to = "" }) => {
  const notYouText = first_name
    ? i18next.t("auth:form.not_user_html", {
        first_name: first_name,
        email: email,
      })
    : null;
  return (
    <div className="size--xxsmall">
      {notYouText}
      <a
        href={Routes.okta_logout_path({
          locale: null,
          redirect_to: redirect_to,
        })}
      >
        {i18next.t("auth:form.sign_out_html")}
      </a>
    </div>
  );
};

export default SignOutLink;
