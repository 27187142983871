import SubmitBtn from "../../form_elements/SubmitBtn";

function InitialCta({ isLookingUpEmail, submitBtn, dataLabel, buttonText = "get_started" }) {
  return (
    <SubmitBtn
      buttonClasses="buttonLarge"
      dataLabel={dataLabel}
      disabled={isLookingUpEmail}
      extraClasses="padding--bottomNone"
      locale={buttonText}
      ref={submitBtn}
    />
  );
}

export default InitialCta;
