import { OktaAuth } from "@okta/okta-auth-js";

const oktaConfig = {
  issuer: `${AUTH_SERVICE_URI}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${SYSTEM_URL}/auth-callback`,
  authorizeUrl: `${AUTH_SERVICE_URI}/oauth2/default/v1/authorize`,
  userinfoUrl: `${AUTH_SERVICE_URI}/oauth2/default/v1/userinfo`,
  tokenManager: { storage: "sessionStorage" },
  pkce: false,
  withCredentials: true,
};

const getIdTokenFromSessionToken = (sessionToken) => {
  const authClient = new OktaAuth(oktaConfig);

  return authClient.token.getWithoutPrompt({
    responseType: "id_token",
    sessionToken,
  });
};

const setLoginCookies = (tokenOrTokens, articulateId) =>
  fetch("/okta/set_login_cookies", {
    body: JSON.stringify({
      articulateId,
      id_token: tokenOrTokens.tokens.idToken.value,
    }),
    credentials: "include",
  })
    .then((response) => response.json())
    .catch(() => {});

export { getIdTokenFromSessionToken, setLoginCookies };
