import _ from "lodash";
import {
  TOGGLE_HOMEPAGE_TRIAL_FORM,
  TOGGLE_TOUR_CTA_TRIAL_FORM,
  TOGGLE_TOUR_CTA_BTNS,
} from "../actions/ui";

const initialState = {
  homepageTrialInitialFormIsOpen: true,
  tourCtaTrialInitialFormIsOpen: true,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_HOMEPAGE_TRIAL_FORM: {
      const formName = `homepageTrial${action.payload.formName}FormIsOpen`;
      return _.merge({}, state, action.payload);
    }
    case TOGGLE_TOUR_CTA_TRIAL_FORM: {
      const formName = `tourCtaTrial${action.payload.formName}FormIsOpen`;
      return _.merge({}, state, action.payload);
    }
    case TOGGLE_TOUR_CTA_BTNS: {
      return _.merge({}, state, { tourCtaTrialExpandedFormDidOpen: true });
    }
    default:
      return state;
  }
};

export default uiReducer;
