import _ from "lodash";

const _buildClassName = ({ name, size, color, right, styleclass }) => {
  const classNames = [
    _iconClass(name),
    _mediaClass(right),
    _sizeClass(size),
    _colorClass(color),
    _styleClass(styleclass),
  ];
  return classNames.filter((name) => name).join(" ");
};

const _mediaClass = (right = false) => {
  const side = right ? "--right" : "";
  return `media__img${side}`;
};

const _iconClass = (name) => `icon-${name}`;
const _sizeClass = (size) => (size ? `size--${size}` : "");
const _colorClass = (color) => (color ? `color--${color}` : "");
const _styleClass = (style) => (style ? style : "");

const Icon = (props) => {
  return (
    <span
      className={_buildClassName(props)}
      onClick={props.onClick || _.noop}
    />
  );
};

export default Icon;
