import React, { MouseEventHandler } from "react";

interface Props {
  isActive: boolean;
  handleClose: MouseEventHandler;
  children: JSX.Element;
  modalClass?: string;
  modalName: string;
}

function Modal({
  children,
  isActive,
  handleClose,
  modalClass,
  modalName,
}: Props): JSX.Element {
  return (
    <div
      className={`modal__wrapper size--medium ${isActive ? "active" : null}`}
    >
      <div
        className={`modal ${modalClass}`}
        data-label={`${modalName}-modal`}
        role="dialog"
        aria-labelledby="modalHeader"
        aria-describedby="modalBody"
      >
        <button
          className="modal__closeBtn"
          data-label="modal-close-btn"
          aria-label="close"
          type="button"
          onClick={handleClose}
        >
          <span className="icon-times size--xsmall color--quarternary media__img" />
        </button>
        <div className="align--center">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
