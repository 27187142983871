import React, { Children, Component } from "react";

class LocaleWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localesLoaded: false,
    };

    const localesLoad = () => {
      if (i18next.t("test:loaded") == "loaded") {
        window.setTimeout(localesLoad, 100);
      } else {
        this.setState({ localesLoaded: true });
      }
    };

    window.setTimeout(localesLoad, 100);
  }

  render() {
    if (this.state.localesLoaded) {
      return <div>{this.props.children}</div>;
    } else {
      return null;
    }
  }
}

export default LocaleWrapper;
