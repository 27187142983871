import React from "react";
import SignOutLink from "../../form_elements/SignOutLink";
import SignInLink from "../../form_elements/SignInLink";
import ServiceAgreement from "../../form_elements/ServiceAgreement";
import SubmitBtn from "../../form_elements/SubmitBtn";

function CTAs({
  requiresMarketingOptIn,
  isLoggedIn,
  isExistingUser,
  signOutName,
  email,
  firstName,
  submitBtn,
  isRequestingTrialActivation,
  isLookingUpEmail,
  ids,
  loginUrl,
}) {
  let sessionLink;
  let first_name = firstName ? firstName : signOutName;
  if (isLoggedIn || isExistingUser)
    sessionLink = <SignOutLink email={email} first_name={first_name} />;
  else
    sessionLink = (
      <SignInLink
        redirect_to={window.location.toString()}
        loginUrl={loginUrl}
      />
    );

  return (
    <div className="align--center inline-trial-submit-button">
      <ServiceAgreement
        product="360"
        requiresMarketingOptIn={requiresMarketingOptIn}
        ids={ids}
        loggedIn={isLoggedIn}
      />
      <SubmitBtn
        ref={submitBtn}
        dataLabel="inlineTrialActivateButton"
        disabled={isRequestingTrialActivation || isLookingUpEmail}
        locale="activate_360_html"
        buttonClasses="activate-trial-inline"
      />
      {sessionLink}
    </div>
  );
}

export default CTAs;
