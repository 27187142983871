const REQUEST_LOOKUP_USER_BY_EMAIL = "REQUEST_LOOKUP_USER_BY_EMAIL";
const RESOLVE_LOOKUP_USER_BY_EMAIL = "RESOLVE_LOOKUP_USER_BY_EMAIL";

function resolveLookupUserByEmail(payload) {
  return {
    type: RESOLVE_LOOKUP_USER_BY_EMAIL,
    payload,
  };
}

function requestLookupUserByEmail(email) {
  return (dispatch) => {
    dispatch({ type: REQUEST_LOOKUP_USER_BY_EMAIL });
    return fetch(`/okta/lookup_by_email?email=${email.replace("+", "%2B")}`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return response
          .json()
          .then((data) => {
            if (data.error === "SSO Enabled") {
              dispatch(resolveLookupUserByEmail(data));
            } else {
              throw new Error("user not found");
            }
          })
          .catch(() => {
            dispatch(resolveLookupUserByEmail());
          });
      })
      .then((json) => {
        dispatch(resolveLookupUserByEmail(json));
      })
      .catch(() => {
        dispatch(resolveLookupUserByEmail());
      });
  };
}

export {
  requestLookupUserByEmail,
  REQUEST_LOOKUP_USER_BY_EMAIL,
  RESOLVE_LOOKUP_USER_BY_EMAIL,
};
