import ProgressCircle from "../icons/ProgressCircle";
import classNames from "classnames";

const ProgressBar = () => {
  return (
    <div className="attachment__icon attachment__icon--inline">
      <ProgressCircle percent={25} className="attachment__spinner" />
    </div>
  );
};

export default ProgressBar;
