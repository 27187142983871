import React, { Component } from "react";
import PropTypes from "prop-types";
import { frames } from "../animations";
import "./image-carousel.scss";
import CarouselImg from "../CarouselImg/CarouselImg";

let visibilityChange = "visibilitychange";

if (typeof document.msHidden !== "undefined") {
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  visibilityChange = "webkitvisibilitychange";
}

class ImageCarousel extends Component {
  constructor(props) {
    super();

    this.state = {
      isActive: false,
      isOpening: false,
      imgCount: props.imgCount,
      isVisible: !document.hidden,
      leaving: null,
      staged: props.visibleCount,
      cardPositions: [...Array(props.visibleCount).keys()],
    };

    this.rotateSlides = this.rotateSlides.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.startCarousel = this.startCarousel.bind(this);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    if ("IntersectionObserver" in window) {
      // If browser supports IntersectionObserver we set up an observer and start carousel when it comes into view
      const observer = new IntersectionObserver(
        this.handleIntersection,
        observerOptions
      );
      if (this.containerRef.current)
        observer.observe(this.containerRef.current);
    } else {
      // If browser does not support IntersectionObserver (ie11) we start carousel on mount.
      this.startCarousel();
    }
    document.addEventListener(
      visibilityChange,
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleVisibilityChange() {
    this.setState({ isVisible: !document.hidden });
  }

  handleIntersection(entries) {
    const { isOpening, isActive } = this.state;
    const [entry] = entries;
    if (entry.isIntersecting && !(isOpening || isActive)) {
      setTimeout(this.startCarousel, frames(30));
    }
  }

  startCarousel() {
    this.setState({
      isOpening: true,
    });
    setTimeout(() => {
      this.setState({
        isOpening: false,
        isActive: true,
      });
      setTimeout(() => {
        this.rotateSlides();
        this.interval = setInterval(this.rotateSlides, frames(200));
      }, frames(120));
    }, frames(30));
  }

  incrementPosition(i) {
    const { imgCount } = this.state;
    return i === imgCount - 1 ? 0 : i + 1;
  }

  updateCardPositions(cardPositions) {
    return cardPositions.map((p) => this.incrementPosition(p));
  }

  rotateSlides() {
    const { isVisible } = this.state;
    if (isVisible) {
      this.setState((prevState) => ({
        leaving: prevState.cardPositions[0],
        staged: this.incrementPosition(prevState.staged),
        cardPositions: this.updateCardPositions(prevState.cardPositions),
      }));
    }
  }

  render() {
    const { carouselName, imgCount, labels, visibleCount } = this.props;
    const { cardPositions, leaving, staged, isOpening, isActive } = this.state;
    const imgs = [];
    for (let i = 0; i <= imgCount - 1; i += 1) {
      const label = labels && labels[i] ? labels[i] : null;
      imgs.push(
        <CarouselImg
          key={i}
          carouselName={carouselName}
          label={label}
          imgNumber={i}
          positions={cardPositions}
          leaving={leaving === i}
          staged={staged === i}
          isOpening={isOpening}
          isActive={isActive}
        />
      );
    }
    return (
      <div
        id={`${carouselName}-carousel`}
        ref={this.containerRef}
        className="carousel-container"
        data-visible-count={visibleCount}
      >
        {imgs}
      </div>
    );
  }
}

ImageCarousel.propTypes = {
  carouselName: PropTypes.string.isRequired,
  imgCount: PropTypes.number.isRequired,
  visibleCount: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
};

ImageCarousel.defaultProps = {
  labels: [],
  visibleCount: 3,
};

export default ImageCarousel;
