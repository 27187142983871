import React, { Component } from "react";
import Input from "../../form_elements/Input";
import InitialCta from "./InitialCta";
import CTAs from "../shared/CTAs";
import FullExpandedFields from "../shared/FullExpandedFields";
import Fade from "../../transitions/Fade";
import SlideDown from "../../transitions/SlideDown";

class TourCtaForm extends Component {
  constructor(props) {
    super(props);
    this.password = React.createRef();
    this.form = React.createRef();
    this.focusPassword = () => {
      this.password.current.focus();
    };
  }

  componentDidMount() {
    // this prevents the element from bouncing as the initial submit buttons disappear and before the expanded form expands into place.
    const height = window
      .getComputedStyle(this.form.current)
      .getPropertyValue("height");
    this.form.current.style["min-height"] = height;
  }

  render() {
    const {
      ids,
      isLoggedIn,
      isExistingUser,
      signOutName,
      isLookingUpEmail,
      defaultEmail,
      isRequestingTrialActivation,
      shouldShowInitial,
      shouldShowExpanded,
      didExpand,
      ...props
    } = this.props;
    const email = props.email || "";
    let password;
    let expandedFields;
    if (shouldShowExpanded) {
      password = (
        <Input
          inputRef={this.password}
          id={ids.password}
          name="password"
          type="password"
          isExistingUser={isExistingUser}
        />
      );
      if (!isLoggedIn && !isExistingUser) {
        expandedFields = (
          <FullExpandedFields
            countries={props.countries}
            geoLocation={props.geoLocation}
            ids={ids}
          />
        );
      }
    }

    return (
      <form
        ref={this.form}
        onSubmit={props.handleSubmit.bind(null, isExistingUser)}
        autoComplete="off"
      >
        <Input
          isShowingProgress={isLookingUpEmail}
          id={ids.email}
          name="email"
          type="email"
          defaulValue={email}
          errorLocale={props.activationError}
          error={props.activationError}
          disabled={isLoggedIn && defaultEmail}
        />
        <Fade
          inProp={shouldShowInitial}
          onExited={props.showExpanded}
          unmountOnExit={true}
        >
          <div className="align--center">
            <InitialCta
              dataLabel="inlineTrialGetStartedButton"
              isLookingUpEmail={isLookingUpEmail}
              submitBtn={props.initialSubmitBtn}
              buttonText={props.reach ? "try_free" : "get_started"}
            />
          </div>
          {props.reach ? (
            <div className="mt-12 text-center">
              <p className="mb-0 text-m">
                {i18next.t("auth:form.already_a_customer")}
              </p>
              <a className="link--underline text-m" href="/lp/request-reach-360">
                {i18next.t("auth:form.get_a_trial")}
              </a>
            </div>
          ) : null}
        </Fade>
        <SlideDown
          inProp={shouldShowExpanded}
          onEnter={this.focusPassword}
          onEntered={props.handleDidExpand}
          mountOnEnter={true}
        >
          <div>
            {password}
            {expandedFields}
            <Fade inProp={didExpand}>
              <CTAs
                submitBtn={props.expandedSubmitBtn}
                isLoggedIn={isLoggedIn}
                isExistingUser={isExistingUser}
                email={email}
                firstName={props.firstName}
                signOutName={signOutName}
                ids={ids}
                requiresMarketingOptIn={props.requiresMarketingOptIn}
                isRequestingTrialActivation={isRequestingTrialActivation}
                isLookingUpEmail={isLookingUpEmail}
                loginUrl={Routes.trial_path({
                  lp: "existing",
                  existing: "true",
                })}
              />
            </Fade>
          </div>
        </SlideDown>
      </form>
    );
  }
}

export default TourCtaForm;
