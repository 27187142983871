export const TOGGLE_HOMEPAGE_TRIAL_FORM = "TOGGLE_HOMEPAGE_TRIAL_FORM";
export const TOGGLE_TOUR_CTA_TRIAL_FORM = "TOGGLE_TOUR_CTA_TRIAL_FORM";
export const TOGGLE_TOUR_CTA_BTNS = "TOGGLE_TOUR_CTA_BTNS";

export function toggleHomepageTrialForm(formName, isOpen) {
  const key = `homepageTrial${formName}FormIsOpen`;

  return {
    type: TOGGLE_HOMEPAGE_TRIAL_FORM,
    payload: { [key]: isOpen },
  };
}

export function toggleTourCtaTrialForm(formName, isOpen) {
  const key = `tourCtaTrial${formName}FormIsOpen`;

  return {
    type: TOGGLE_TOUR_CTA_TRIAL_FORM,
    payload: { [key]: isOpen },
  };
}

export function toggleTourCtaBtns() {
  return { type: TOGGLE_TOUR_CTA_BTNS };
}
