import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import { updateInputValue } from "../../../actions/forms";
import { submitInitialInlineForm } from "../../../actions/trials";
import ProgressBar from "../../form_elements/ProgressBar";
import Fade from "../../transitions/Fade";

const mapStateToProps = ({ forms }, ownProps) => {
  return _.merge(
    {
      reference: ownProps.reference,
    },
    {
      value: forms[ownProps.ids.email],
      error: forms[`${ownProps.ids.email}Error`],
    },
    ownProps
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateInputValue: ({ inputId, value }) => {
      return dispatch(updateInputValue({ inputId, value }));
    },
    handleSubmit: ({ inputId, submitBtn, isLoggedIn, toggleModal }, e) => {
      e.preventDefault();
      return dispatch(
        submitInitialInlineForm({
          inputId,
          submitBtn,
          isLoggedIn,
          expandForm: toggleModal,
        })
      );
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return _.merge(
    { reference: stateProps.reference },
    stateProps,
    dispatchProps,
    {}
  );
};

const btnStackBreak = 640;

const slideDuration = 300;

class InitialForm extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.slideBtnLeft = this.slideBtnLeft.bind(this);
    this.handleVideoClick = this.handleVideoClick.bind(this);
    this.finishSlide = this.finishSlide.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.showVideoBtn = this.showVideoBtn.bind(this);
    this.submitBtn = React.createRef();
    this.videoBtnDiv = React.createRef();
    this.videoBtn = React.createRef();
    this.handleSubmit = props.handleSubmit.bind(this, {
      inputId: props.ids.email,
      submitBtn: this.submitBtn,
      isLoggedIn: props.isLoggedIn,
      toggleModal: props.hideInitial,
    });
    this.state = {
      shouldShowVideoBtn: true,
      videoBtnExited: false,
      submitShouldSlide: true,
      getStartedTransform: 0,
      btnSlideDist: 0,
      windowWidth: 0,
      buttonWidth: 0,
    };
    if (props.isLoggedIn) {
      // setting redux value of email input to match logged in user, so validation works for logged in user
      this.handleInputChange({ target: { value: props.defaultEmail } });
    }
  }

  componentDidMount() {
    var buttonWidth = this.videoBtnDiv.current.offsetWidth;
    var slideDist = buttonWidth / 2;
    this.setState({
      btnSlideDist: slideDist,
      windowWidth: window.innerWidth,
      buttonWidth: this.videoBtn.current.offsetWidth,
    });
    window.addEventListener("resize", this.updateWidth);
  }

  updateWidth() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleInputChange(e) {
    this.props.updateInputValue({
      inputId: this.props.ids.email,
      value: e.target.value,
    });
    if (e.target.value == "") {
      this.slideBtnRight();
    } else if (this.state.shouldShowVideoBtn != false) {
      this.setState({ shouldShowVideoBtn: false });
    }
  }

  handleVideoClick(e) {
    var video_trigger = document.getElementById(this.props.wistiaTriggerId);
    video_trigger.click();
  }

  slideBtnRight() {
    if (this.state.windowWidth > btnStackBreak) {
      this.setState({
        submitShouldSlide: true,
        getStartedTransform: this.state.btnSlideDist + "px",
      });
      setTimeout(this.showVideoBtn, slideDuration);
    }
  }

  slideBtnLeft() {
    // If we are above button stacking breakpoint move get started button over to center after video button fades out.
    if (this.state.windowWidth > btnStackBreak) {
      this.setState({
        getStartedTransform: "-" + this.state.btnSlideDist + "px",
      });
      setTimeout(this.finishSlide, slideDuration);
      this.setState({ submitShouldSlide: true });
    }
  }

  showVideoBtn() {
    this.setState({
      submitShouldSlide: false,
      videoBtnExited: false,
      getStartedTransform: "0px",
      shouldShowVideoBtn: true,
    });
  }

  finishSlide() {
    // After submit button has slid over we hide the video btn div and reset the transform so submit button will remain centered if screen size changes
    this.setState({
      submitShouldSlide: false,
      videoBtnExited: true,
      getStartedTransform: "0px",
    });
  }

  render() {
    const {
      ids,
      error,
      value,
      defaultEmail,
      isLoggedIn,
      isLookingUpEmail,
      shouldShowExpanded,
      reference,
    } = this.props;
    let errorEl;
    if (error) {
      errorEl = (
        <div className="form__error">
          {i18next.t(`auth:form.errors.email.${error}`)}
        </div>
      );
    }

    let label;
    if (value && !isLoggedIn) {
      label = (
        <label htmlFor={ids.email}>
          {i18next.t(`auth:form.placeholder.email`)}
        </label>
      );
    }

    let progressBar;
    if (isLookingUpEmail) {
      progressBar = <ProgressBar />;
    }

    let videoDivClassNames = classNames(
      "grid__col--6",
      "grid__col--medium--12",
      "float--left",
      {
        "align--right": this.state.windowWidth > btnStackBreak,
        "align--center": this.state.windowWidth <= btnStackBreak,
      }
    );

    let buttonMargins = {
      margin: "0 0.5em 0",
    };

    let getStartedStyles;
    if (this.state.submitShouldSlide) {
      getStartedStyles = {
        transition: "transform " + slideDuration + "ms ease-out",
      };
    }

    let getStartedClasses = classNames(
      "grid__col--6",
      "grid__col--medium--12",
      "grid__col--bleed",
      "margin--bottomSmall",
      {
        "align--center": this.state.windowWidth <= btnStackBreak,
        "float--right": !this.state.videoBtnExited,
      }
    );

    if (!shouldShowExpanded) {
      return (
        <div className="home__inlineTrial--initial" ref={reference}>
          <div className="align--center">
            <h1
              className="padding--topLarge padding--portrait--topDefault"
              dangerouslySetInnerHTML={{
                __html: i18next.t("inline_trial:homepage.header_html"),
              }}
            />
            <h3
              className="align--center"
              dangerouslySetInnerHTML={{
                __html: i18next.t("inline_trial:homepage.subheader_html"),
              }}
            />
          </div>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <div className={classNames("form__group", { errors: error })}>
              <input
                onChange={this.handleInputChange}
                id={ids.email}
                type="text"
                disabled={isLoggedIn && defaultEmail}
                value={value || defaultEmail}
                placeholder={i18next.t(
                  "inline_trial:homepage.email_placeholder"
                )}
                name="email"
              />
              {progressBar}
              {label}
              {errorEl}
            </div>
            <div className="form__group align--center">
              <div
                className={getStartedClasses}
                style={{
                  ...getStartedStyles,
                  transform:
                    "translateX(" + this.state.getStartedTransform + ")",
                }}
              >
                <button
                  ref={this.submitBtn}
                  disabled={isLookingUpEmail}
                  type="submit"
                  className="btn--primary"
                  style={{
                    ...buttonMargins,
                    minWidth: this.state.buttonWidth + "px",
                  }}
                >
                  {i18next.t("inline_trial:homepage.get_started")}
                </button>
              </div>
              <div className={videoDivClassNames} ref={this.videoBtnDiv}>
                <Fade
                  inProp={this.state.shouldShowVideoBtn}
                  onExited={this.slideBtnLeft}
                  unmountOnExit={true}
                >
                  <a
                    className="btn--secondary"
                    onClick={this.handleVideoClick}
                    style={buttonMargins}
                    ref={this.videoBtn}
                  >
                    <span className="media--inline">
                      <span className="media__img icon-play size--xxxsmall"></span>
                      <span className="media__body">
                        {i18next.t("inline_trial:homepage.watch_video")}
                      </span>
                    </span>
                  </a>
                </Fade>
              </div>
            </div>
          </form>
        </div>
      );
    } else
      return (
        <div
          className="section--fullBg img--background background--homeBanner home__hero"
          id="homepage-hero"
        />
      );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InitialForm);
