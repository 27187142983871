import _ from "lodash";
import * as forms from "../actions/forms";

const formsReducer = (state = {}, action) => {
  switch (action.type) {
    case forms.UPDATE_INPUT_VALUE:
      return _.merge({}, state, {
        [action.inputId]: action.value,
        [`${action.inputId}Error`]: "",
      });

    case forms.INVALIDATE_INPUT_VALUES:
      const errors = action.errors.reduce((acum, cur) => {
        acum[`${cur.inputId}Error`] = cur.error;
        return acum;
      }, {});
      return _.merge({}, state, errors);

    default:
      return state;
  }
};

export default formsReducer;
