import React from "react";
import PropTypes from "prop-types";
import "./carousel-img.scss";
import classNames from "classnames";

function CarouselImg({
  isOpening,
  isActive,
  leaving,
  staged,
  carouselName,
  imgNumber,
  label,
  positions,
}) {
  const openingClass = isOpening
    ? "articulate-animation articulate-t1 active"
    : null;
  const activeClass = isActive
    ? "articulate-animation articulate-carousel1 active"
    : "";
  const leavingClass = leaving ? "leaving" : "";
  const stagedClass = staged ? "staged" : "";
  const imgClasses = `carousel__img ${carouselName}Carousel__img--${imgNumber} ${leavingClass} ${openingClass} ${activeClass} ${stagedClass}`;
  return (
    <div
      className={classNames("img--background", imgClasses)}
      data-aos-mobile
      data-position={positions.indexOf(imgNumber)}
    >
      <div className="carousel-label">{label}</div>
    </div>
  );
}

CarouselImg.propTypes = {
  isOpening: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  leaving: PropTypes.bool.isRequired,
  staged: PropTypes.bool.isRequired,
  carouselName: PropTypes.string.isRequired,
  imgNumber: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CarouselImg;
