import React from "react";
import classNames from "classnames";

const SubmitBtn = React.forwardRef(
  (
    {
      locale,
      dataLabel,
      disabled,
      extraClasses = "",
      buttonClasses = "",
    },
    ref
  ) => {
    return (
      <div className={"form__group " + extraClasses}>
        <button
          data-label={dataLabel}
          ref={ref}
          className={classNames("btn--primary", buttonClasses)}
          disabled={disabled}
          dangerouslySetInnerHTML={{
            __html: i18next.t(`auth:form.submit.${locale}`),
          }}
        />
      </div>
    );
  }
);

export default SubmitBtn;
