import _ from "lodash";

export function getInputError(inputType, value) {
  if (inputType == "email") {
    if (!value || value.length >= 70) return "invalid";
    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(value)) return "invalid";
  } else if (inputType == "password") {
    const pattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.{8,128})/g;
    if (!pattern.test(value)) return "invalid";
  } else if (inputType == "phone") {
    // using lodash's trim in case the values are `undefined`
    if (_.trim(value).length < 4) return "too_short";
  } else if (typeof value === "boolean") {
    if (value == false) return "blank";
  }
  if (!_.trim(value)) return "blank";
}
