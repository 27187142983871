import React, { Component } from "react";
import Icon from "../Icon";

class InputHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
    this.handleTooltipClick = this.handleTooltipClick.bind(this);
  }

  handleTooltipClick() {
    this.setState((state) => {
      return { showTooltip: !state.showTooltip };
    });
  }

  renderInputHelper(isExistingUser, showTooltip, name) {
    if (isExistingUser) {
      return (
        <a href={Routes.reset_redirect_path({ locale: null })}>
          {i18next.t("auth:form.forgot_password_html")}
        </a>
      );
    } else {
      return (
        <div
          onClick={this.handleTooltipClick}
          className="popover__trigger attached"
        >
          <Icon name="info" size="small" color="quarternary lineHeight--one" />
          <div
            className={`popover--tooltip section__content left compact ${
              showTooltip ? "active" : ""
            }`}
            style={{ top: "50%", transform: "translate(-100%, -50%)" }}
          >
            <div className="size--xsmall">
              {i18next.t(`auth:form.errors.${name}.helper`)}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { showTooltip } = this.state;
    const { name } = this.props;
    const { isExistingUser } = this.props;

    return (
      <div className="input__helper">
        {this.renderInputHelper(isExistingUser, showTooltip, name)}
      </div>
    );
  }
}

export default InputHelper;
