import React, { Component } from "react";
import { Transition } from "react-transition-group";

const duration = 600;

const defaultStyle = {
  overflow: "hidden",
  opacity: 0,
  transition: `max-height ${duration}ms`,
};

class SlideDown extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.updateElementHeight = () => {
      props.onEnter();
      this.setState({
        entering: { maxHeight: 0 },
        //Set max height to twice current computed height.  This leaves plenty of room for form error messages.
        entered: {
          opacity: 1,
          overflow: "visible",
          maxHeight: `${
            parseInt(
              window
                .getComputedStyle(this.child.current, null)
                .getPropertyValue("height"),
              10
            ) * 2
          }px`,
        },
      });
    };
    this.state = {};
  }

  render() {
    const { inProp, children, onEnter, ...props } = this.props;
    return (
      <Transition
        in={inProp}
        timeout={duration}
        onEnter={this.updateElementHeight}
        {...props}
      >
        {(state) => (
          <div
            ref={this.child}
            style={{
              ...defaultStyle,
              ...this.state[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  }
}

export default SlideDown;
