import React, { Component } from "react";
import { Provider } from "react-redux";
import HomepageTrial from "./HomepageTrial";
import TourCtaTrial from "./TourCtaTrial";
import store from "../../store";

const getChild = (childName) => {
  // these types should be connected elements that get their own state and dispatch
  const types = {
    tourCta: TourCtaTrial,
    homepage: HomepageTrial,
  };
  return types[childName];
};

class TrialProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localesLoaded: false,
    };

    const localesLoad = () => {
      if (
        i18next.t("inline_trial:homepage.header_html") == "homepage.header_html"
      ) {
        window.setTimeout(localesLoad, 100);
      } else {
        this.setState({ localesLoaded: true });
      }
    };

    window.setTimeout(localesLoad, 100);
  }

  render() {
    const child = getChild(this.props.type);

    if (this.state.localesLoaded) {
      return (
        <Provider store={store}>
          {React.createElement(child, this.props)}
        </Provider>
      );
    } else return null;
  }
}

export default TrialProvider;
