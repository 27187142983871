import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import * as forms from "../../actions/forms";
import InputHelper from "./InputHelper";
import ProgressBar from "./ProgressBar";

const mapStateToProps = ({ forms }, props) => {
  const stateProps =
    {
      value: forms[props.id] || props.defaultValue,
      error: forms[`${props.id}Error`],
    } || props.error;
  return _.merge({ inputRef: props.inputRef }, stateProps, props);
};

const mapDispatchToProps = (dispatch, { id }) => {
  return {
    updateInputValue: _.debounce((value) => {
      // debouncing here so that when there are many form fields all on the same page the page doesn't slow down significantly as the user types
      return dispatch(forms.updateInputValue({ inputId: id, value }));
    }, 300),
  };
};

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
    this.props.updateInputValue(e.target.value);
  }

  render() {
    const {
      disabled,
      name,
      type,
      id,
      error,
      value,
      defaultValue = "",
      isShowingProgress,
      updateInputValue,
      errorLocale,
      onBlur = _.noop,
      inputRef,
      isExistingUser,
    } = this.props;

    const props = {
      value: this.state.value || defaultValue,
      disabled,
      id,
      type,
      name,
      onBlur,
      onChange: this.handleChange,
    };
    props.onChange = this.handleChange;

    let label;
    if (type !== "hidden") {
      label = (
        <label className="!text-[#767676]" htmlFor={id}>{i18next.t(`auth:form.placeholder.${name}`)}</label>
      );
    }
    let helper;
    if (type == "password")
      helper = <InputHelper name={name} isExistingUser={isExistingUser} />;

    let errorElement;
    if (error) {
      let content;
      const locale = errorLocale || `auth:form.errors.${name}.${error}`;

      if (locale.match("need_to_reset") && name == "password") {
        content = i18next.t(locale, {
          reset_url: Routes.new_account_password_path(),
        });
      } else if (locale.match("sso_enabled_html") && name == "email") {
        content = i18next.t(locale, {
          login_url: Routes.login_redirect_path({
            locale: i18next.locale,
            redirect_to: THREESIXTY_FRONTEND_URI,
            external_redirect: true,
          }),
        });
      } else {
        content = i18next.t(locale);
      }

      errorElement = (
        <div
          className="form__error"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }

    let progressBar;
    if (isShowingProgress) {
      progressBar = <ProgressBar />;
    }

    return (
      <div className={classNames("form__group", { errors: error })}>
        <input
          className={classNames({ dontLift: !props.value })}
          ref={inputRef}
          {...props}
        />
        {progressBar}
        {label}
        {helper}
        {errorElement}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
