import classNames from "classnames";

const ProgressCircle = ({
  strokeColor,
  trailWidth,
  strokeWidth,
  percent,
  trailColor,
  className,
}) => {
  const radius = 50 - strokeWidth / 2;
  const pathString = `
    M 50,50 m 0,-${radius}
    a ${radius},${radius} 0 1 1 0,${2 * radius}
    a ${radius},${radius} 0 1 1 0,-${2 * radius}`;
  const len = Math.PI * 2 * radius;
  const pathStyle = {
    strokeDasharray: `${len}px ${len}px`,
    strokeDashoffset: `${((100 - percent) / 100) * len}`,
    transition: "stroke-dashoffset 0.3s ease 0s, stroke 0.3s ease",
  };
  return (
    <svg
      className={classNames(className, "rc-progress-circle")}
      viewBox="0 0 100 100"
    >
      <path
        className="rc-progress-circle-trail"
        d={pathString}
        stroke={trailColor}
        strokeWidth={trailWidth || strokeWidth}
        fillOpacity="0"
      />
      <path
        className="rc-progress-circle-path"
        d={pathString}
        strokeLinecap="round"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fillOpacity="0"
        style={pathStyle}
      />
    </svg>
  );
};

ProgressCircle.defaultProps = {
  strokeWidth: 12,
  strokeColor: "#979797",
  trailWidth: 12,
  trailColor: "#d9d9d9",
};

export default ProgressCircle;
