import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as forms from "../../actions/forms";

const mapStateToProps = ({ forms }, props) => {
  return _.merge({}, props, {
    value: forms[props.id] || props.defaultValue,
    error: forms[`${props.id}Error`],
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInputValue: ({ inputId, value }) => {
      return dispatch(forms.updateInputValue({ inputId, value }));
    },
  };
};

// Sort correctly based on locale.
const _sortedOptions = (xs, promoted = []) => {
  return xs.sort((a, b) => {
    for (let p in promoted) {
      if (a.value == p) return -1;
      if (b.value == p) return 1;
    }
    return a.name.localeCompare(b.name, i18next.language);
  });
};

// # If no value selected, set USA & CAN to be promoted to the top of the list.
const _isPromoted = (value) => {
  if (value) return [];
  return ["USA", "CAN"];
};

const _buildOption = (o) => {
  return (
    <option key={o.value} value={o.value}>
      {o.name}
    </option>
  );
};

class CountrySelect extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    // calling update input value right away to ensure preloaded values don't trigger blank invalid error (basically hydrating store)
    props.updateInputValue({ inputId: props.id, value: props.defaultValue });
  }

  handleChange(e) {
    this.props.updateInputValue({
      inputId: this.props.id,
      value: e.target.value,
    });
  }

  render() {
    const {
      id,
      value,
      defaultValue,
      className,
      countries,
      updateInputValue,
    } = this.props;
    const options = _sortedOptions(countries, _isPromoted(value)).map(
      _buildOption
    );

    return (
      <div className="form__group">
        <select
          id={id}
          value={value || defaultValue}
          className={classNames(className, {
            complete: Boolean(value || defaultValue),
          })}
          onChange={this.handleChange}
        >
          {options}
        </select>
        <label htmlFor={id} name={id}>
          {i18next.t("auth:form.placeholder.country")}
        </label>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
