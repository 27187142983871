import React from "react";
import ReactPlayer from "react-player/lazy";

interface Props {
  autoplay?: boolean;
  noControls?: boolean;
  hideDropShadow?: boolean;
  videoId: string;
  repeat?: boolean;
  muted?: boolean;
}

const dropShadow = { filter: "drop-shadow(0 0 120px rgb(0 0 0 / 10%))" };

const VideoPlayer = ({
  autoplay,
  hideDropShadow,
  muted = true,
  noControls,
  repeat,
  videoId,
}: Props) => (
  <div className="relative pt-[56.25%]">
    <ReactPlayer
      className="absolute top-0 left-0 mx-auto w-full h-full"
      data-label="video-player"
      controls={noControls ? false : true}
      playing={autoplay ? true : false}
      config={{
        wistia: {
          options: {
            endVideoBehavior: repeat ? "loop" : "default",
          },
        },
      }}
      volume={muted ? 0 : 1}
      muted={muted}
      height="100%"
      pip
      stopOnUnmount={false}
      style={hideDropShadow ? null : dropShadow}
      url={`https://fast.wistia.com/medias/${videoId}`}
      width="100%"
    />
  </div>
);

export default VideoPlayer;
