import _ from "lodash";

import { requestLookupUserByEmail } from "./session";
import { validateFormData } from "./forms";
import trackTrialActivation from "./helpers/ga";
import {
  getIdTokenFromSessionToken,
  setLoginCookies,
} from "../common/utils/okta";
import { getRedirectURLWithSearchParams } from "../../assets/javascripts/modules/helpers/searchParams";

const REQUEST_TRIAL_ACTIVATION = "REQUEST_TRIAL_ACTIVATION";
const RESOLVE_TRIAL_ACTIVATION = "RESOLVE_TRIAL_ACTIVATION";

const trialActivationUrl = (id = null) =>
  `${ACCOUNT_MANAGEMENT_URI}/subscriptions/${id}/invite?trial=success`;

function oktaLogin(oktaResponse, redirect, action) {
  return (dispatch) => {
    getIdTokenFromSessionToken(oktaResponse.sessionToken)
      .then((tokenOrTokens) => {
        setLoginCookies(tokenOrTokens, oktaResponse.articulateId).then(() => {
          if (redirect) {
            window.location = redirect;
          }

          return dispatch(action);
        });
      })
      .catch(() => { });
  };
}

function resolveTrialActivation({ error, payload, formData }) {
  const action = { type: RESOLVE_TRIAL_ACTIVATION, error };
  const data = formData;
  let redirect;

  if (data) {
    delete data.password;
  }

  if (error) {
    return action;
  }

  switch (payload.activate_status) {
    case "error": {
      action.error = "auth:form.errors.email.generic";
      break;
    }
    case "unverified": {
      action.error = "auth:form.errors.email.unverified";
      break;
    }
    case "expired": {
      redirect = Routes.expired_trial_path();
      break;
    }
    case "domain_blocked": {
      action.error = "auth:form.errors.email.domain_blocked";
      break;
    }
    case "active": {
      redirect = THREESIXTY_FRONTEND_URI;
      break;
    }
    case "activated": {
      trackTrialActivation();

      if (payload.login_status === "logged_in") {
        redirect = trialActivationUrl(payload.subscription_id);
      } else {
        redirect = Routes.trial_path({
          email: _.trim(data.email),
          success: true,
          lp: "existing",
        });
      }
      break;
    }
    case "created": {
      redirect = Routes.page_path("lp/trial/success");
      break;
    }
    default: {
      return null;
    }
  }

  if (payload.okta_response) {
    const oktaResponse = payload.okta_response.table;

    if (oktaResponse.sessionToken && oktaResponse.articulateId) {
      return oktaLogin(oktaResponse, redirect, action);
    }
  }

  if (redirect) {
    window.location = getRedirectURLWithSearchParams(redirect);
  }
  return action;
}

function updateConsentInMarketo(email, token) {
  const data = {
    locale: null,
    email,
    pathname: window.location.pathname,
    authenticity_token: token,
  };
  const init = {
    method: "POST",
    credentials: "same-origin",
    body: "",
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }),
  };
  const request = new Request(Routes.accept_consent_path(data), init);

  fetch(request);
}

function fetchTrialActivation({ formData, token, requiresMarketingOptIn }) {
  updateConsentInMarketo(formData.email, token);

  const data = _.merge({}, formData, {
    campaign_opt_in: formData.marketingConsent || !requiresMarketingOptIn,
    lead_source: WWW_TRIAL_LEAD_SOURCE,
    query_string: window.location.search,
    utm_landing_page: window.location.href,
    redirect_params: window.location.search,
    authenticity_token: token,
  });
  const init = {
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  const request = new Request("/lp/activation", init);
  return (dispatch) => {
    fetch(request)
      .then((response) => response.json())
      .then((payload) => {
        dispatch(resolveTrialActivation({ formData, payload }));
      })
      .catch(() =>
        dispatch(
          resolveTrialActivation({ error: "free_trial:activation_error" })
        )
      );
  };
}

function submitInitialInlineForm({
  inputId,
  submitBtn,
  isLoggedIn,
  expandForm,
}) {
  if (submitBtn.current) {
    submitBtn.current.focus(); // focus on the submit btn (and thus sending in the submit btn) is necessary bc of global js that is clearing errors on inputs. this can be removed if we are able to change all our inputs to the new es6 version
  }

  return (dispatch, getState) => {
    dispatch(validateFormData({ formFields: { email: inputId } }))
      .then(() => {
        if (isLoggedIn) {
          expandForm();
        } else {
          const email = getState().forms[inputId];

          dispatch(requestLookupUserByEmail(email)).then(() => {
            expandForm();
          });
        }
      })
      .catch(() => { });
  };
}

function submitExpandedInlineForm({
  formFields,
  isLoggedIn,
  token,
  requiresMarketingOptIn,
}) {
  return (dispatch, getState) => {
    const fields = _.merge({}, formFields);
    const { session, forms } = getState();
    const password = forms[formFields.password];

    if ((session.isExistingUser || isLoggedIn) && _.trim(password)) {
      delete fields.password;
    }

    delete fields.marketingConsent; // this field is not required so we dont' need to validate it

    dispatch(validateFormData({ formFields: fields }))
      .then(() => {
        const formData = Object.keys(formFields).reduce((acum, curr) => {
          const current = curr;
          const accumulator = acum;

          accumulator[current] = forms[formFields[current]];

          return accumulator;
        }, {});

        if (session.didLookupEmail || isLoggedIn) {
          dispatch({ type: REQUEST_TRIAL_ACTIVATION });

          return dispatch(
            fetchTrialActivation({
              formData,
              token,
              requiresMarketingOptIn,
            })
          );
        }

        const email = forms[formFields.email];
        dispatch(requestLookupUserByEmail(email)).then(() => {
          // if the user changed their email between the inline form and expanded version,
          // if the previous email was not found to have an existing account but their new email
          // is associated with an existing acct, we can try to activate them, otherwise
          // we need to show them the full expanded form to get their name, etc. the `getState()`
          // call is current, and session object is cached from previous form submit
          const oldUserFound = session.isExistingUser;
          const newUserFound = getState().session.isExistingUser;
          const eligibleForTrial =
            oldUserFound === newUserFound || (newUserFound && !oldUserFound);

          if (eligibleForTrial) {
            dispatch({ type: REQUEST_TRIAL_ACTIVATION });

            return dispatch(
              fetchTrialActivation({
                formData,
                token,
                requiresMarketingOptIn,
              })
            );
          }

          return Promise.reject();
        });

        return null;
      })
      .catch(_.noop); // just catching with a no-op so we don't see an uncaught error message... error is expected when form does not validate.
  };
}

export {
  REQUEST_TRIAL_ACTIVATION,
  RESOLVE_TRIAL_ACTIVATION,
  submitInitialInlineForm,
  submitExpandedInlineForm,
};
