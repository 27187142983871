import _ from "lodash";
import * as session from "../actions/session";
import * as forms from "../actions/forms";

const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case forms.UPDATE_INPUT_VALUE:
      if (action.inputId.includes("email")) {
        return _.merge({}, state, { didLookupEmail: false });
      }
      return state;
    case session.REQUEST_LOOKUP_USER_BY_EMAIL:
      return _.merge({}, state, { isLookingUpEmail: true });
    case session.RESOLVE_LOOKUP_USER_BY_EMAIL:
      if (action.payload && action.payload.error == "SSO Enabled") {
        return _.merge({}, state, {
          isLookingUpEmail: false,
          didLookupEmail: true,
          isExistingUser: true,
          emailLookupError: "auth:form.errors.email.sso_enabled_html",
        });
      }
      return _.merge({}, state, {
        isLookingUpEmail: false,
        didLookupEmail: true,
        isExistingUser: Boolean(action.payload),
        firstName: action.payload ? action.payload.first_name : "",
      });

    default:
      return state;
  }
};

export default sessionReducer;
