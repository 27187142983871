import { combineReducers } from "redux";
import ui from "./ui";
import forms from "./forms";
import session from "./session";
import trials from "./trials";

const rootReducer = combineReducers({
  ui,
  forms,
  session,
  trials,
});

export default rootReducer;
