import React, { Component } from "react";
import Input from "../../form_elements/Input";

class BaseExpandedFields extends Component {
  constructor(props) {
    super(props);
    this.passwordInput = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (this.props.visible && !oldProps.visible && !this.props.isLoggedIn)
      this.passwordInput.current.focus();
  }

  render() {
    const {
      isLoggedIn,
      ids,
      defaultEmail,
      errors,
      activationError,
      isLookingUpEmail,
      isExistingUser,
    } = this.props;
    return (
      <div>
        <Input
          isShowingProgress={isLookingUpEmail}
          id={ids.email}
          name="email"
          type="email"
          errorLocale={activationError}
          error={activationError}
          disabled={isLoggedIn && defaultEmail}
        />
        <Input
          id={ids.password}
          inputRef={this.passwordInput}
          name="password"
          type="password"
          isExistingUser={isExistingUser || isLoggedIn}
        />
      </div>
    );
  }
}

export default BaseExpandedFields;
