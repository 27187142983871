import Input from "../../form_elements/Input";
import CountrySelect from "../../form_elements/CountrySelect";

const FullExpandedFields = ({ countries, geoLocation, ids }) => {
  return (
    <div>
      <Input
        id={ids.first_name}
        name="first_name"
        type="text"
      />
      <Input
        id={ids.last_name}
        name="last_name"
        type="text"
      />
      <Input
        id={ids.company}
        name="company"
        type="text"
      />
      <CountrySelect
        id={ids.country}
        name="country"
        countries={countries}
        defaultValue={geoLocation}
      />
      <Input id={ids.phone} name="phone" type="text" />
    </div>
  );
};

export default FullExpandedFields;
