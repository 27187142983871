import { getInputError } from "./helpers/validation";

const UPDATE_INPUT_VALUE = "UPDATE_INPUT_VALUE";
const INVALIDATE_INPUT_VALUES = "INVALIDATE_INPUT_VALUES";

function updateInputValue({ inputId, value }) {
  return {
    type: UPDATE_INPUT_VALUE,
    inputId,
    value,
  };
}

function invalidateInputValues(errors) {
  return {
    type: INVALIDATE_INPUT_VALUES,
    errors,
  };
}

function validateFormData({ formFields }) {
  return (dispatch, getState) => {
    const errors = Object.keys(formFields)
      .map((inputType) => {
        const inputId = formFields[inputType];
        const value = getState().forms[inputId];
        return {
          inputId,
          error: getInputError(inputType, value),
        };
      })
      .filter(({ error }) => error);
    if (errors.length) {
      dispatch(invalidateInputValues(errors));
      return Promise.reject();
    }
    return Promise.resolve();
  };
}

export {
  updateInputValue,
  invalidateInputValues,
  validateFormData,
  UPDATE_INPUT_VALUE,
  INVALIDATE_INPUT_VALUES,
};
