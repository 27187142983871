function CookieRow({
  cookie: { host, name, description, isSession, persistence, ...rest },
  sessionCopy,
  persistenceCopy,
}) {
  return (
    <tr className="align--left">
      <td className="size--xxsmall">{host}</td>
      <td className="size--xxsmall">{name}</td>
      <td className="size--xxsmall">{description}</td>
      <td className="size--xxsmall">
        {isSession ? sessionCopy : persistenceCopy}
      </td>
    </tr>
  );
}

function CookieTable({
  cookies = [],
  tableHeadings = {},
  sessionCopy,
  persistenceCopy,
}) {
  return (
    <table>
      <thead>
        <tr className="align--left size--small">
          <th width="23%">
            <h5>{tableHeadings.domain}</h5>
          </th>
          <th width="23%">
            <h5>{tableHeadings.name}</h5>
          </th>
          <th width="32%">
            <h5>{tableHeadings.purpose}</h5>
          </th>
          <th width="20%">
            <h5>{tableHeadings.persistence}</h5>
          </th>
        </tr>
      </thead>
      <tbody>
        {cookies.map((cookie, i) => (
          <CookieRow
            key={`${cookie.name}-${i}`}
            cookie={cookie}
            persistenceCopy={persistenceCopy}
            sessionCopy={sessionCopy}
          />
        ))}
      </tbody>
    </table>
  );
}

export default CookieTable;
