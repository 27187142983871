import moment from "moment";

const LocalizedDate = ({ date }) => {
  const dateArr = date.split("/");
  const day = Number(dateArr[1]);
  const month = Number(dateArr[0] - 1);
  const year = Number(dateArr[2]);
  const language = i18next.language.split("-")[0];
  moment.locale(language);
  return (
    <span
      className="nowrap"
      dangerouslySetInnerHTML={{
        __html: moment(new Date(year, month, day)).format("LL"),
      }}
    />
  );
};

export default LocalizedDate;
