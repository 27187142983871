import _ from "lodash";
import {
  REQUEST_TRIAL_ACTIVATION,
  RESOLVE_TRIAL_ACTIVATION,
} from "../actions/trials";

const trialsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_TRIAL_ACTIVATION: {
      return _.merge({}, state, {
        isRequestingTrialActivation: true,
        trialActivationError: null,
      });
    }
    case RESOLVE_TRIAL_ACTIVATION: {
      return _.merge({}, state, {
        isRequestingTrialActivation: false,
        trialActivationError: action.error,
        didActivateTrial: !action.error,
      });
    }
    default:
      return state;
  }
};

export default trialsReducer;
