import React, { Component } from "react";
import _ from "lodash";
import SectionModal from "../../modals/SectionModal";
import CTAs from "../shared/CTAs";
import FullExpandedFields from "../shared/FullExpandedFields";
import BaseExpandedFields from "../shared/BaseExpandedFields";

class ExpandedForm extends Component {
  constructor(props) {
    super(props);
    this.submitBtn = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.submitBtn.current.focus();
    let formFields = this.props.ids;
    if (this.props.isExistingUser || this.props.isLoggedIn) {
      formFields = {
        email: this.props.ids.email,
        password: this.props.ids.password,
        marketingConsent: this.props.ids.marketingConsent,
        termsConsent: this.props.ids.termsConsent,
      };
    }
    //TODO trace and remove requiresMarketingOptin
    this.props.handleExendedFormSubmit({
      formFields,
      token: this.props.csfrToken,
      isLoggedIn: this.props.isLoggedIn,
      requiresMarketingOptIn: this.props.requiresMarketingOptIn,
    });
  }

  render() {
    const {
      hideExpanded,
      shouldShowExpanded,
      shouldShowInitial,
      isLookingUpEmail,
      isLoggedIn,
      firstName,
      ids,
      countries,
      geoLocation,
      defaultEmail,
      email,
      activationError,
      isExistingUser,
      signOutName,
      didActivateTrial,
      isRequestingTrialActivation,
      reference,
      requiresMarketingOptIn,
    } = this.props;

    const modalLabel = "modalLabel";
    let fullExpandedFields;
    const showFullFieldset = !isLoggedIn && !isExistingUser;
    if (showFullFieldset) {
      fullExpandedFields = (
        <FullExpandedFields
          ids={ids}
          countries={countries}
          geoLocation={geoLocation}
        />
      );
    }

    if (didActivateTrial) {
      return (
        <h2 className="align--center transition-label">
          {i18next.t("inline_trial:please_wait_html")}
        </h2>
      );
    } else if (!shouldShowInitial) {
      return (
        <SectionModal
          className="home__inlineTrial"
          toggleSectionModal={hideExpanded}
          ariaLabelledby={modalLabel}
        >
          <form
            ref={reference}
            className="margin--topNone"
            onSubmit={this.handleSubmit}
            autoComplete="off"
          >
            <h3
              id={modalLabel}
              className="align--center"
              dangerouslySetInnerHTML={{
                __html: i18next.t("inline_trial:homepage.subheader_html"),
              }}
            />
            <BaseExpandedFields
              visible={shouldShowExpanded}
              isLoggedIn={isLoggedIn}
              ids={ids}
              defaultEmail={defaultEmail}
              activationError={activationError}
              isExistingUser={isExistingUser}
              isLookingUpEmail={isLookingUpEmail}
            />
            {fullExpandedFields}
            <CTAs
              loginUrl={Routes.trial_path({ existing: "true", lp: "existing" })}
              ids={ids}
              requiresMarketingOptIn={requiresMarketingOptIn}
              isLookingUpEmail={isLookingUpEmail}
              isRequestingTrialActivation={isRequestingTrialActivation}
              isLoggedIn={isLoggedIn}
              isExistingUser={isExistingUser}
              signOutName={signOutName}
              firstName={firstName}
              email={email}
              submitBtn={this.submitBtn}
            />
          </form>
        </SectionModal>
      );
    } else {
      return null;
    }
  }
}

export default ExpandedForm;
