import React, { ReactElement, useState } from "react";
import DOMPurify from "../../../lib/domPurify";

interface Input {
  field: string;
  label: string;
  type?: string;
  value?: string;
  pattern?: string;
  padded?: boolean;
  fullWidth?: boolean;
  errorMessages?: ErrorMessages;
  children?: ReactElement;
}

interface ErrorMessages {
  valueMissing?: string;
  patternMismatch?: string;
}

function Input({
  field,
  label,
  type = "text",
  value = null,
  pattern = null,
  padded = false,
  fullWidth = false,
  errorMessages,
  children,
}: Input) {
  const [fieldEmpty, setFieldEmpty] = useState(!value);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setErrorMessage("");
    setFieldEmpty(!target.value);
  };

  const getErrorMessage = (currentField: HTMLInputElement) => {
    const validity = currentField.validity;
    if (validity.valueMissing)
      return (
        errorMessages?.valueMissing ||
        `Please enter your ${label.toLowerCase()}.`
      );
    if (validity.patternMismatch)
      return (
        errorMessages?.patternMismatch ||
        `Please enter a valid ${label.toLowerCase()}.`
      );
  };

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    setErrorMessage(getErrorMessage(event.target));
  };

  return (
    <div
      className={`relative | w-full | group | hover:cursor-text | mb-12
    ${errorMessage ? "| text-red-error" : ""}
    ${fullWidth ? " col-span-2" : ""}`}
    >
      <label htmlFor={field} className="w-full">
        <input
          className={`peer | !text-m | !pb-px | sm:!text-xl | sm:!pb-1 | font-graphik
                    ${
                      value
                        ? "| cursor-not-allowed | !text-gray-aa | transition-all"
                        : ""
                    }
                    ${padded ? "| !pr-36 | sm:!pr-48" : ""}
                    ${errorMessage ? "| !border-red-error" : ""}`}
          type={type}
          id={field}
          name={field}
          defaultValue={value}
          onChange={handleChange}
          required={true}
          readOnly={!!value}
          pattern={pattern}
          onInvalid={handleInvalid}
          aria-describedby={`${field}-errormessage`}
          aria-invalid={errorMessage ? true : false}
        />
        <span
          className={`absolute | text-black | cursor-text
                      | group-focus-within:text-xxs | group-focus-within:bottom-8 | group-focus-within:text-gray-aa | group-focus-within:leading-8
                      | sm:group-focus-within:text-s
                    ${
                      fieldEmpty
                        ? "| bottom-2 | transition-all | text-s | xs:text-m | sm:text-xl"
                        : "| text-xxs | leading-8 | bottom-8 | text-gray-aa | sm:text-s"
                    }
                    ${errorMessage ? "| !text-red-error" : ""}`}
        >
          {label}
        </span>
      </label>
      {children}
      {errorMessage ? (
        <span
          id={`${field}-errormessage`}
          className="absolute | -bottom-5 | text-xs | font-normal | left-0"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(errorMessage),
          }}
        ></span>
      ) : null}
    </div>
  );
}

export default Input;
